/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

li {
  list-style: none;
}

/* FORM STYLING STARTS HERE */
.form-input {
  width: 100%;
  height: 100%;
  border: grey solid 1px;
  border-radius: 6px;
}

.form-input > div {
  padding: 4px;
}

.input-box-divider {
  display: flex;
  position: relative;
  border-bottom: grey solid 1px;
  max-height: 55px;
}

.input-box-end {
  display: flex;
  position: relative;
  max-height: 100px;
}

.form-input input {
  min-width: 98%;
  padding: 18px 0px 15px 8px;
  /* border: solid red 1px; */
  border: none;
  background-color: transparent;
}

.form-input textarea {
  margin: 18px 0px 5px 5px;
}

.input-textarea {
  display: block;
  overflow: hidden;
  height: 80px;
}

.form-input label {
  color: #878787;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  padding-left: 12px;
  font-size: 14px;
  user-select: none;
  position: absolute;
  height: 100%;
  transition: 0.5s ease-in-out;
  cursor: text;
}

.form-input input:valid + label,
.form-input input:focus + label {
  color: #878787;
  font-size: 11px;
  top: -12px;
}

.form-input textarea:valid + label,
.form-input textarea:focus + label {
  color: #878787;
  font-size: 11px;
  top: -35px;
}

.form-input input:focus,
.form-input textarea:focus {
  outline: none;
}

.form-button {
  background-color: #ff385c;
  border: none;
  border-radius: 6px;
  width: 100%;
  margin: 20px 0px;
  padding: 15px;
  font-weight: bold;
  color: white;
}
.form-button:hover {
  cursor: pointer;
  background-color: #ff254d;
}

.or-divider-wrapper {
  width: 100%;
}

.or-divider {
  font-size: 11px;
  display: flex;
  align-items: center;
  color: #222;
}

.or-divider::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-right: 16px;
  background-color: #dddddd;
}

.or-divider::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-left: 16px;
  background-color: #dddddd;
}

.errors-style {
  color: red;
  margin-bottom: 10px;
}
