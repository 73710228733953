.nav-parent {
  display: flex;
  /* position: absolute; */
  position: fixed;
  width: 100%;
  top: 0;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  background-color: white;
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 20px 0px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  width: 100%;
}

.home-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  user-select: none;
}

.home-icon a {
  color: #ff385c;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
}
