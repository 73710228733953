#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #modal.showModal {
  opacity: 1;
} */

/* #modal:target {
  opacity: 1;
} */

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: 250ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 alternate
    modal-background-enter;
}

#modal-content {
  /* max-height: 85vh; */
  max-height: 95vh;
  overflow-y: hidden;
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: 250ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 alternate
    modal-content-on;
}

.modal-background-off {
  opacity: 0;
  transition: 250ms cubic-bezier(0.25, 0.1, 0.25, 1);
}
.modal-content-off {
  opacity: 0;
  transform: translateY(70px);
  transition: 250ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes modal-content-on {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
}

/* @keyframes modal-background-on {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
