.dates-container {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;
  width: fit-content;
  margin: 10px 0px;
  /* padding: 10px; */
}

.booking-date-container-l {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid rgb(221, 221, 221);
}

.booking-date-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.booking-date-container-l input,
.booking-date-container input {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;
  width: 90%;
  padding: 2px;
  margin: 5px 0px 0px;
}

.booking-header {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

.create-booking-button {
  width: 100%;
  background-color: #ff385c;
  border: none;
  border-radius: 6px;
  margin: 10px 0px 0px;
  padding: 15px;
  font-weight: bold;
  color: white;
}

.booking-errors {
  margin-top: 10px;
  text-align: center;
  color: red;
}

.booking-pls-login {
  cursor: default;
  user-select: none;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: grey;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.booking-not-charged {
  text-align: center;
  margin: 20px 0px 0px;
  font-size: 13px;
}

.fee-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fees {
  display: flex;
  justify-content: space-between;
}
.fees-underline {
  text-decoration: underline;
}

.fee-total {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  border-top: 1px solid rgb(221, 221, 221);
  padding-top: 15px;
}
