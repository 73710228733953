.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 40px; */
}

.login-form-container {
  background-color: white;
  border: grey solid 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  /* margin-top: 52px; */
  box-shadow: 0 2px 16px rgb(0 0 0 / 12%);
}

.login-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.modal-close-button {
  position: relative;
  left: 20px;
  background-color: transparent;
  outline: none;
  border: none;
}

.modal-close-button:hover {
  cursor: pointer;
}

.login-title {
  padding: 20px;
  font-weight: bold;
}

.login-form-wrapper {
  display: block;
  flex-direction: column;
  align-items: left;
  width: 100%;
}

.login-pane {
  padding: 32px;
}

.welcome-wrapper {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}

/* .form-input {
  width: 100%;
  border: grey solid 1px;
  border-radius: 6px;
} */

/* .form-input > div {
  padding: 4px;
} */

/* .login-input-box {
  border-bottom: grey solid 1px;
} */

/* .login-input-box,
.password-input-box {
  max-height: 55px;
} */

/* .form-input input {
  min-width: 95%;
  padding: 10px 0px 15px 8px;
  border: none;
  background-color: transparent;
} */

/* .form-input input::placeholder {
  position: absolute;
  top: 3px;
} */

/* .form-input input:focus {
  outline: none;
} */

/* .form-input label {
  color: #878787;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  padding-left: 12px;
  font-size: 14px;
  user-select: none;
  position: absolute;
  height: 100%;
  transition: 0.5s ease-in-out;
  cursor: text;
} */

/* .form-input input:valid + label,
.form-input input:focus + label {
  color: #878787;
  font-size: 11px;
  top: -12px;
} */

/* .login-input-box:focus-within label,
.password-input-box:focus-within label {
  color: grey;
  top: 2px;
  font-size: 11px;
} */

/* .login-button {
  background-color: #ff385c;
  border: none;
  border-radius: 6px;
  width: 100%;
  margin: 20px 0px;
  padding: 15px;
  font-weight: bold;
  color: white;
} */

/* .login-button:hover {
  cursor: pointer;
} */

/* input.email-input:focus::placeholder,
input.password-input:focus::placeholder {
  position: unset;
} */

.demo-button {
  border: black solid 1px;
  border-radius: 6px;
  width: 100%;
  margin: 20px 0px;
  padding: 15px;
  color: black;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.demo-button:hover {
  cursor: pointer;
  background-color: #ebebeb;
}

.demo-button-icon {
  margin-left: 6px;
}

/* .or-divider-wrapper {
  width: 100%;
} */

/* .or-divider {
  font-size: 11px;
  display: flex;
  align-items: center;
  color: #222;
} */

/* .or-divider::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-right: 16px;
  background-color: #dddddd;
} */

/* .or-divider::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-left: 16px;
  background-color: #dddddd;
} */
